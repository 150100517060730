<template>
  <div>
    <v-footer class="app_footer" app>
      <v-layout>
        <v-btn text small class="footer_button_link" @click="language_dialog = true">
          <v-icon left small>language</v-icon>
          {{ $t("common.language") }}: {{ $t("common._current_language") }}
        </v-btn>

        <v-btn v-if="$vuetify.breakpoint.name != 'xs'" text small class="footer_button_link" @click="contact_us_dialog = true">
          <v-icon left small>phone</v-icon>
          {{ $t("common.contact_us") }}
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.name != 'xs'" text small class="footer_button_link" @click="report_a_problem_dialog = true">
          <v-icon left small>contact_support</v-icon>
          {{ $t("common.report_problem") }}
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.name != 'xs'" text small class="footer_button_link" @click="get_help_dialog = true">
          <v-icon left small>help</v-icon>
          {{ $t("common.get_help") }}
        </v-btn>

        <v-spacer />

        <!-- Admin stuff -->
        <template v-if="current_user.is_admin && current_clinic">
          <v-btn
            v-if="current_clinic.stripe_subscription_id"
            class="clinic_page_menu_button mt-n1 pt-0 mb-n1 pb-0"
            x-small
            text
            target="_blank"
            :href="'https://dashboard.stripe.com/subscriptions/' + current_clinic.stripe_subscription_id"
          >
            <v-icon small left>fab fa-stripe</v-icon>Subscription
          </v-btn>

          <v-btn
            v-if="current_clinic.stripe_customer_id"
            class="clinic_page_menu_button mt-n1 pt-0 mb-n1 pb-0"
            x-small
            text
            target="_blank"
            :href="'https://dashboard.stripe.com/customers/' + current_clinic.stripe_customer_id"
          >
            <v-icon small left>fab fa-stripe</v-icon>Customer
          </v-btn>

          <v-btn class="clinic_page_menu_button mt-n1 pt-0 mb-n1 pb-0" x-small text :href="'/app/admin/clinic/' + current_clinic.id" target="_blank">
            <v-icon x-small left>fas fa-tools</v-icon>
          </v-btn>
        </template>

        <v-btn v-if="show_introduction_video" text small class="footer_button_link" @click="tour_dialog = true">
          <v-icon left small>mdi-star-face</v-icon>
          <strong>{{ $t("footer.introductory_video") }}</strong>
        </v-btn>

        <v-btn v-if="$vuetify.breakpoint.name != 'xs' && current_clinic" text small class="referral_code">
          <span v-if="referral_code" @click="ref_dialog = true">
            referral code:
            <span class="referral_code_code">{{ referral_code }}</span
            >&nbsp;&nbsp;-
          </span>
        </v-btn>

        <v-btn text small class="terms_privacy">
          <a href="/terms">{{ $t("footer.terms") }}</a
          >&nbsp;&nbsp;-&nbsp;&nbsp;
          <a href="/privacy">{{ $t("footer.privacy") }}</a>
        </v-btn>
      </v-layout>
    </v-footer>

    <!-- Contact Us -->
    <v-dialog v-model="contact_us_dialog" max-width="600px">
      <v-card v-if="contact_us_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="contact_us_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("common.contact_us") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <YourVisionSpecialist
            v-if="contact_person"
            :contact_person="contact_person"
            :contact_clinic="contact_clinic"
            :title="$t('footer.contact_your_vision_specialist')"
            style="margin-top: 30px; margin-bottom: 30px"
          />

          <v-sheet :elevation="sheet_elevation" style="padding: 30px; margin-top: 20px" class="report_a_problem_text">
            <h2 v-if="contact_person" style="margin-bottom: 10px">
              {{ $t("footer.contact_ocudigital") }}
            </h2>
            <p v-if="contact_person" style="font-style: italic">
              {{ $t("footer.creators_of_neurovisual_trainer") }}
            </p>

            <v-alert v-if="$i18n.locale != 'en'" type="info" style="margin-top: 10px">
              <p style="margin-bottom: -3px">
                {{ $t("contact_us.english_only_support_message") }}
              </p>
            </v-alert>
            <ContactInfo :include_chat="current_clinic_role != 'user'" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Report A Problem -->
    <v-dialog v-model="report_a_problem_dialog" max-width="800px">
      <v-card v-if="report_a_problem_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="report_a_problem_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("common.report_problem") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-alert v-if="$i18n.locale != 'en'" type="info" style="margin-top: 3px">
            <p style="margin-bottom: -3px">
              {{ $t("footer.english_only_support_message") }}
            </p>
          </v-alert>
          <div class="report_a_problem_text" style="margin-top: 30px">
            <p>{{ $t("footer.report_problem_first_text") }}</p>
            <p v-if="current_clinic_role != 'user'">
              {{ $t("footer.report_problem_second_text") }}
            </p>
            <v-btn v-if="current_clinic_role != 'user'" color="primary" style="margin-left: 138px; margin-bottom: 20px" @click="open_chat">{{
              $t("common.start_online_chat")
            }}</v-btn>
            <p>{{ $t("footer.report_problem_third_text") }}</p>
          </div>

          <ContactInfo :include_chat="false" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tour -->
    <v-dialog v-model="tour_dialog" max-width="800px">
      <v-card v-if="tour_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="tour_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("footer.introductory_video") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <WelcomeVideo :elevation="0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Get Help -->
    <v-dialog v-model="get_help_dialog" max-width="800px">
      <v-card v-if="get_help_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="get_help_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("common.get_help") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <YourVisionSpecialist :contact_person="contact_person" :contact_clinic="contact_clinic" />
          <v-sheet :elevation="sheet_elevation" style="padding: 30px; margin-top: 20px" class="report_a_problem_text">
            <h2 v-if="contact_person || contact_clinic" style="margin-bottom: 10px">
              {{ $t("footer.technical_support") }}
            </h2>
            <p v-if="contact_person || contact_clinic" style="font-style: italic">
              {{ $t("footer.for_help_with_neurovisual_trainer") }}
            </p>
            <v-alert v-if="$i18n.locale != 'en'" type="info" style="margin-top: 3px">
              <p style="margin-bottom: -3px">
                {{ $t("footer.english_only_support_message") }}
              </p>
            </v-alert>
            <p v-if="!contact_person">{{ $t("footer.we_are_help") }}</p>
            <p>{{ $t("footer.help_first_text") }}</p>

            <v-layout justify-space-around style="margin-bottom: 20px">
              <v-flex md5>
                <v-btn color="primary" href="http://help.ocudigital.com" target="_blank">{{ $t("footer.open_knowlege_base") }}</v-btn>
              </v-flex>

              <v-flex v-if="current_clinic_role !== 'user'" md5>
                <v-btn color="primary" @click="open_chat">{{ $t("common.start_online_chat") }}</v-btn>
              </v-flex>
            </v-layout>

            <div v-if="!contact_person && !contact_clinic">
              <p>{{ $t("footer.help_second_text") }}</p>
              <ContactInfo :include_chat="false" />
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Language Dialog -->
    <v-dialog v-model="language_dialog" max-width="800px">
      <v-card v-if="language_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="language_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("footer.select_language") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-list>
            <v-list-item v-for="(item, i) in languages" :key="i" @click="set_language(item)">
              <v-list-item-avatar>
                <v-icon v-if="current_language == item.code">check</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Referral Dialog -->
    <v-dialog v-model="ref_dialog" max-width="800px">
      <v-card v-if="ref_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="ref_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Referral Program</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <ReferralCodeInfo v-if="current_clinic" :clinic="current_clinic" :include_title="false" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// TODO: Add some copy-paste helpers. When you click td, select all text.

import ContactInfo from "./ContactInfo.vue";
import cookie from "js-cookie";
import YourVisionSpecialist from "./YourVisionSpecialist";
import ReferralCodeInfo from "./ReferralCodeInfo";
import WelcomeVideo from "./WelcomeVideo";

import { mapState } from "vuex";

export default {
  components: {
    ContactInfo,
    YourVisionSpecialist,
    ReferralCodeInfo,
    WelcomeVideo,
  },
  props: {
    assigned_staff: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      contact_us_dialog: false,
      report_a_problem_dialog: false,
      get_help_dialog: false,
      tour_dialog: false,
      language_dialog: false,
      ref_dialog: false,
    };
  },
  computed: {
    ...mapState(["small_screen", "current_clinic_id", "current_clinic", "current_user", "user_clinics", "current_clinic_role", "highest_perm"]),
    languages() {
      let languages = [
        { code: "en", title: "English" },
        { code: "es", title: "Español" },
        { code: "fr", title: "Français" },
      ];

      if (this.current_user.is_admin || window.location.hostname.startsWith("stage")) {
        languages.push({ code: "pt", title: "Português (br) - Preview" });
      }
      if (this.current_user.is_admin || window.location.hostname.startsWith("stage")) {
        languages.push({ code: "he", title: "עברית - Work in Progress" });
      }
      return languages;
    },
    show_introduction_video() {
      if (this.small_screen) {
        return false;
      }
      if (this.highest_perm == "admin" || this.highest_perm == "staff") {
        let d = new Date();
        d.setMonth(d.getMonth() - 3);
        if (this.current_user.created.substr(0, 10) > d.toISOString().substr(0, 10)) {
          return true;
        }
      }
      return false;
    },
    referral_code() {
      if (this.current_clinic_role == "admin" || this.current_clinic_role == "staff") {
        return this.current_clinic.tag_value("ref_code");
      }
      return "";
    },
    current_language() {
      return this.$i18n.locale.slice(0, 2);
    },
    contact_person() {
      for (var i in this.assigned_staff) {
        let staff = this.assigned_staff[i];
        if (staff.clinic_id == this.current_clinic_id) {
          return staff;
        }
      }
      return null;
    },
    contact_clinic() {
      for (var i in this.assigned_staff) {
        let staff = this.assigned_staff[i];
        if (staff.clinic_id == this.current_clinic_id) {
          for (var j in this.user_clinics) {
            let clinic = this.user_clinics[j];
            if (clinic.id == this.current_clinic_id) {
              return clinic;
            }
          }
        }
      }
      return null;
    },
    sheet_elevation() {
      if (this.contact_person) {
        return 2;
      }
      return 0;
    },
  },
  methods: {
    open_chat() {
      this.$root.$children[0].open_helpscout();
    },
    set_language(lang_item) {
      cookie.set("opticalgym_locale", lang_item.code);
      localStorage.removeItem("opticalgym_select_text_lang");
      this.$router.go();
    },
  },
};
</script>

<style>
.app_footer {
  font-size: smaller;
}

.theme--light footer.app_footer {
  border-top: solid 1px;
  border-color: lightgrey;
}

.app_footer a {
  text-decoration: none;
}

.theme--light .app_footer a {
  color: black;
}

.theme--dark .app_footer a {
  color: white;
}

.report_a_problem_text {
  font-size: 16px;
}

.contact_us_open_chat {
  padding: 0px !important;
  height: 24px !important;
  font-size: 16px !important;
  color: #1976d2 !important;
  text-transform: lowercase;
  font-weight: normal;
  text-decoration: underline;
  letter-spacing: normal;
  font-weight: normal;
}

.footer_button_link {
  margin-left: 30px;
  font-size: 14px !important;
  height: 14px !important;
  text-transform: lowercase;
  font-weight: normal;
  letter-spacing: normal;
  font-weight: normal;
}

.terms_privacy {
  font-size: 12px !important;
  height: 12px !important;
  text-transform: lowercase;
  font-weight: normal;
  letter-spacing: normal;
  font-weight: normal;
}

.referral_code {
  margin-right: -16px !important;
  font-size: 12px !important;
  height: 12px !important;
  text-transform: lowercase;
  font-weight: normal;
  letter-spacing: normal;
  font-weight: normal;
}

.referral_code .referral_code_code {
  text-transform: uppercase;
  font-weight: 520;
}
</style>
