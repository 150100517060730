<template>
  <v-app>
    <!-- iPad rotate -->
    <v-system-bar v-if="is_mobile" id="rotate_banner" color="orange lighten-1" height="60px">
      <v-btn icon style="margin-top: 10px; margin-left: 10px; float: left">
        <v-icon large left>rotate_90_degrees_ccw</v-icon>
      </v-btn>

      <h3 style="padding-top: 15px; margin-left: 60px">NeuroVisual Trainer works best in Landscape mode. Please rotate your device.</h3>
    </v-system-bar>

    <!-- Browser upgrade notice -->
    <div v-if="browser_upgrade_needed">
      <v-container fluid fill-height style="background-color: white; text-align: center">
        <v-layout align-center justify-center>
          <v-sheet elevation="5" style="padding: 50px">
            <div class="headline mb-1">
              {{ $t("app.you_need_to_upgrade_your_browser") }}
            </div>

            <p>{{ $t("app.your_browser_is_out_of_date") }}</p>

            <div v-if="browser_upgrade_needed == 'edge'" style="margin-top: 50px">
              <a href="https://www.microsoft.com/en-us/edge">
                <img src="/assets/edge-icon-256.png" />
              </a>
              <p>
                <a href="https://www.microsoft.com/en-us/edge">{{ $t("app.download_the_latest_version_of_microsoft_edge") }}</a>
              </p>
            </div>
          </v-sheet>
        </v-layout>
      </v-container>
    </div>

    <div v-else>
      <!-- Loading Spinner -->
      <div v-if="app_loading" style="margin-top: 300px">
        <v-container fluid fill-height style="background-color: white">
          <v-layout align-center justify-center>
            <v-progress-circular size="200" width="20" color="primary" indeterminate></v-progress-circular>
          </v-layout>
        </v-container>
      </div>

      <!-- Main App UI -->
      <div v-else>
        <v-navigation-drawer v-if="current_user && !inIframe && !print_mode" stateless :value="true" app :right="$vuetify.rtl" :mini-variant="drawer_mini">
          <v-list>
            <v-list-item class="px-2">
              <img
                v-if="!drawer_mini"
                class="ml-1 mt-2"
                style="height: auto; width: 200px"
                :src="dark_mode_toggle ? '/assets/app/nvt/nvt_wordmark_dark.png' : '/assets/app/nvt/nvt_wordmark.png'"
              />
              <v-tooltip v-if="!drawer_mini" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click.stop="drawer_mini = true">
                    <v-icon> {{ $vuetify.rtl ? "mdi-chevron-right" : "mdi-chevron-left" }} </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("main_menu.minimize_menu") }}</span>
              </v-tooltip>
              <v-btn v-else icon @click.stop="drawer_mini = false">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </v-list-item>

            <v-list-item link class="mt-2">
              <v-list-item-content v-if="!drawer_mini && current_user" @click="account_dialog = true">
                <v-list-item-title>{{ current_user.name_given }} {{ current_user.name_family }}</v-list-item-title>
                <v-list-item-subtitle>{{ current_user.email }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-icon v-if="drawer_mini" @click="account_dialog = true">mdi-account-circle</v-icon>
              <v-list-item-action v-else>
                <v-icon @click="account_dialog = true">mdi-account-circle</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-menu v-if="(user_clinics && user_clinics.length > 1) || (current_user && current_user.is_admin)" offset-x :left="$vuetify.rtl">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item link dense v-bind="attrs" v-on="on">
                  <v-list-item-content v-if="!drawer_mini && current_user">
                    <v-list-item-subtitle v-if="current_clinic && current_clinic.title.length < 30" style="font-size: smaller; font-weight: bold">
                      {{ current_clinic ? current_clinic.title : "" }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else style="font-size: x-small; font-weight: bold">
                      {{ current_clinic ? current_clinic.title : "" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-else>
                    <v-list-item-subtitle v-if="current_clinic_title_initials.length <= 2" style="font-size: smaller; font-weight: bold">
                      {{ current_clinic_title_initials }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="current_clinic_title_initials.length == 3" style="font-size: x-small; font-weight: bold">
                      {{ current_clinic_title_initials }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else style="font-size: 0.5rem; font-weight: bold">
                      {{ current_clinic_title_initials }}
                    </v-list-item-subtitle>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in select_clinic_items" :key="index" link dense @click="select_clinic(item.value)">
                  <v-list-item-title style="cursor: pointer"
                    >{{ item.text }} <v-icon v-if="current_clinic_id == item.value" small>mdi-check</v-icon></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list>
          <v-divider></v-divider>

          <!-- Main Menu -->
          <MainMenu
            v-if="current_user && !inIframe && (user_clinics.length > 0 || current_user.is_admin) && !print_route"
            :user="current_user"
            :clinics="user_clinics"
          />

          <template v-slot:append>
            <v-list>
              <v-list-item link @click="calibration_dialog_model = true">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-6' : 'mr-6'">
                  <v-badge v-if="current_calibration" small overlap bordered color="success" icon="check">
                    <v-icon>settings</v-icon>
                  </v-badge>
                  <v-badge v-else small overlap bordered color="warning" icon="fas fa-exclamation">
                    <v-icon>settings</v-icon>
                  </v-badge>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title style="font-size: 1.1rem">{{ $t("main_menu.calibrate") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Dark Mode Toggle -->
              <v-list-item>
                <v-list-item-action :class="$vuetify.rtl ? 'ml-5' : 'mr-5'">
                  <v-switch v-model="dark_mode_toggle" dense></v-switch>
                </v-list-item-action>

                <v-list-item-title>
                  {{ $t("main_menu.dark_mode") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>

          <!-- User Account dialog -->
          <v-dialog v-model="account_dialog" scrollable max-width="800">
            <UserEdit :user="current_user" show_logout :limit_collection="false" @close-user-account="account_dialog = false" />
          </v-dialog>
        </v-navigation-drawer>

        <!-- Main content -->
        <v-main
          v-if="loaded && ((current_user && (user_clinics.length > 0 || current_user.is_admin)) || password_reset || accept_invite || clinic_signup)"
          id="main_content"
          style="margin-bottom: 40px"
        >
          <!-- Masquarade Banner -->
          <v-system-bar v-if="masquerade" color="orange lighten-1" height="60px">
            <v-btn icon @click="unmasquerade()">
              <v-icon large>close</v-icon>
            </v-btn>

            <!-- <v-icon style="margin-right: 8px">fa-mask</v-icon> -->
            {{ $t("app.viewing_application_as", { name_given: current_user.name_given, name_family: current_user.name_family }) }}
          </v-system-bar>
          <router-view />
        </v-main>
      </div>

      <!-- Footer -->
      <Footer v-if="loaded && current_user && user_clinics && !inIframe && !print_route" :assigned_staff="assigned_staff" :highest_perm="highest_perm" />

      <!-- Calibration dialog -->
      <v-dialog id="calibration_dialog" v-model="calibration_dialog_model" max-width="1300" scrollable>
        <Calibration />
      </v-dialog>

      <!-- Login screen -->
      <v-container v-if="loaded && !current_user && !password_reset && !accept_invite && !clinic_signup" fluid fill-height style="background-color: white">
        <v-layout align-center justify-center>
          <Login />
        </v-layout>
      </v-container>

      <!-- No Clinics / Account archived -->
      <v-container v-if="loaded && current_user && !current_user.is_admin && user_clinics.length == 0" fluid fill-height>
        <v-layout align-center justify-center>
          <AccountArchived></AccountArchived>
        </v-layout>
      </v-container>
    </div>

    <div id="opticalgym-messages" class="opticalgym-messages text-center">
      <!-- Success Messages -->
      <v-snackbar v-model="show_success" :timeout="show_success_timeout" bottom color="success">
        <v-layout justify-center>
          <div>{{ success_message }}</div>
        </v-layout>
      </v-snackbar>

      <!-- API Error Messages -->
      <v-snackbar v-model="show_error" color="error" :timeout="show_error_timeout" min-width="500px">
        <v-btn text style="float: right" @click="show_error = false">Close</v-btn>
        <h2 style="margin-top: 10px">{{ error_message }}</h2>
      </v-snackbar>

      <!-- Vuex Error Snackbar -->
      <v-snackbar v-model="vuex_error_model" color="red" dark>
        Error: {{ vuex_error_message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="vuex_error_model = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import cookie from "js-cookie";
import axios from "axios";
import { boot_helpscout, open_helpscout } from "../shared/lib/helpscout";
import MainMenu from "../shared/components/MainMenu";
import UserEdit from "../shared/components/UserEdit";
import Footer from "../shared/components/Footer";
import Login from "../shared/components/Login";
import Calibration from "../shared/components/Calibration";
import AccountArchived from "../shared/components/AccountArchived";
import UAParser from "ua-parser-js";
import axios_logger from "../shared/lib/axios_logger";
import jQuery from "jquery";
import * as Sentry from "@sentry/browser";

export default {
  name: "App",
  components: {
    MainMenu,
    Footer,
    Login,
    Calibration,
    AccountArchived,
    UserEdit,
  },
  data() {
    let query_params = new URLSearchParams(window.location.search);
    let prefs = cookie.getJSON("user_preferences");
    let dark_mode_toggle = prefs ? prefs.dark_mode : false;
    let drawer_mini = prefs ? prefs.drawer_mini : this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm" || window.innerWidth < 1000;
    return {
      window_height: window.innerHeight,
      window_width: window.innerWidth,
      app_loading: true,
      error: null,
      password_reset: false,
      accept_invite: false,
      clinic_signup: false,
      user_loaded: false,
      exercises_loaded: false,
      show_success: false,
      show_success_timeout: 5000,
      success_message: "",
      show_error: false,
      show_error_timeout: 5000,
      error_message: "",
      account_dialog: false,
      print_mode: query_params.get("print") == "true",
      drawer_mini,
      dark_mode_toggle,
    };
  },
  computed: {
    ...mapState([
      "user_calibrations",
      "user_metadata_loading",
      "current_user",
      "current_calibration",
      "calibration_dialog",
      "vuex_error_message",
      "current_clinic_id",
      "current_clinic",
      "user_clinics",
      "user_metadata",
      "masquerade",
      "user_invites",
      "assigned_staff",
      "ip_country",
      "file_base_url",
      "highest_perm",
      "app_config",
      "is_mobile",
    ]),
    select_clinic_items() {
      let items = [];
      for (let clinic of this.user_clinics) {
        items.push({
          text: clinic.title,
          value: clinic.id,
        });
      }
      return items;
    },
    current_clinic_title_initials() {
      if (!this.current_clinic) {
        return "";
      }

      if (this.current_clinic.title.length <= 4) {
        return this.current_clinic.title;
      }
      let title = this.current_clinic.title.trim();

      // If the title is all uppercase, convert to lowercase
      if (title.toUpperCase() === title) {
        title = title.toLowerCase();
      }

      // Remove "of, and, the, in, on, at, to, with, for, a, an, or, nor, but, yet, so" from the title
      title = this.current_clinic.title.replace(/\b(of|and|the|in|on|at|to|with|for|a|an|or|nor|but|yet|so|Dr)\b/gi, "");

      // Replace '-', ',', '.' with space
      title = title.replace(/[-,.&]/g, " ");

      // Collapse multiple spaces
      title = title.replace(/\s+/g, " ");

      // Split the title into words by spaces or capitals
      let words = title
        .split(/\s+/) // Split on spaces
        .flatMap((word) => {
          // If the word is all uppercase and more than one letter, split into letters
          if (word.toUpperCase() === word && word.length > 1) return word.split("");
          // Otherwise, check for capital letter start followed by lowercase (as a single word)
          // or split by individual uppercase letters
          return word.match(/[A-Z][a-z]*|[A-Z]/g) || [word];
        });

      let initials = words
        .map((word) => {
          return word.charAt(0).toUpperCase();
        })
        .join("");

      // Return the first 4 initials
      return initials.substring(0, 4);
    },
    calibration_dialog_model: {
      set(val) {
        this.$store.commit("setCalibrationDialog", val);
      },
      get() {
        return this.$store.state.calibration_dialog;
      },
    },
    vuex_error_model: {
      set(val) {
        this.$store.commit("setHasErrorMessage", val);
      },
      get() {
        return this.$store.state.vuex_has_error_message;
      },
    },
    loaded() {
      return this.user_loaded;
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    print_route() {
      return window.location.pathname.startsWith("/app/print/");
    },
    browser_upgrade_needed() {
      let ua = UAParser(navigator.userAgent);
      if (ua.engine == "EdgeHTML") {
        return "edge";
      }

      return "";
    },
  },
  watch: {
    dark_mode_toggle: function (value) {
      this.dark_mode(value);
    },
    drawer_mini: (value) => {
      let prefs = cookie.getJSON("user_preferences");
      if (!prefs) {
        prefs = {};
      }
      prefs.drawer_mini = value;
      cookie.set("user_preferences", prefs, 365);
    },
  },
  mounted() {
    Sentry.setTag("app", window.OpticalGymApp);
    Sentry.setTag("mode", window.OpticalGymMode);

    // Check if we are password-resetting
    let router_path = this.$router.currentRoute.path;
    if (router_path == "/app/password_reset") {
      this.password_reset = true;
    }
    // Check if we are accepting an invite
    if (router_path.startsWith("/app/code")) {
      this.accept_invite = true;
    }
    // Check if we are accepting an invite
    if (router_path.startsWith("/app/signup")) {
      this.clinic_signup = true;
    }

    // Process user preferences
    let prefs = cookie.getJSON("user_preferences");
    if (prefs) {
      if (prefs.dark_mode && !this.inIframe) {
        this.$vuetify.theme.dark = prefs.dark_mode;
      }
    }

    // Teleport opticalgym-messages to the body
    let messages_element = document.getElementById("opticalgym-messages");
    let app_element = document.getElementById("app");
    app_element.appendChild(messages_element);

    // Some colour tweaking on the dark and light theme
    this.$vuetify.theme.themes.dark.accent = "#82B1FF";

    // Receive messages from the global message bus
    window.messageBus.$on("dark-mode", this.dark_mode);
    window.messageBus.$on("success", this.success);
    window.messageBus.$on("api-error", this.api_error);
    window.messageBus.$on("error", this.display_error);
    window.messageBus.$on("user-updated", this.user_updated);

    // Log backend errors via axios
    axios_logger(this.application_error);

    // If we are in an iframe, then set a class on the body to indicate this
    if (window.top.location != window.location) {
      jQuery("body").addClass("in-iframe");
    }

    this.$store.dispatch("loadCurrentUser", () => {
      // If we are in iframe, break out if we are logged in
      if (window.top.location != window.location && this.current_user) {
        window.top.location = window.location.href;
      }

      // Boot helpscout if we are not masquerading and not a user
      if (!this.masquerade && this.highest_perm != "user" && !this.print_route) {
        this.boot_helpscout();
      }

      // Load exercises and video exercises if we are logged in
      if (this.current_user) {
        if (this.current_clinic_id) {
          this.$store.dispatch("loadCurrentClinic", {
            clinic_id: this.current_clinic_id,
          });
        }
        this.$store.dispatch("loadExercises", () => {
          this.exercises_loaded = true;
          this.user_loaded = true;
          this.app_loading = false;
        });
      } else {
        this.user_loaded = true;
        this.app_loading = false;
      }
    });
  },
  methods: {
    select_clinic(clinic_id) {
      // Set the current clinic ID (this will set it on the user_preferences cookie)
      this.$store.commit("setCurrentClinicId", clinic_id);

      // If we're in a clinic-specific URL, replace the clinic ID in the URL
      // */clinic/<UUID> or */clinic/<UUID>/*
      // Use a regex
      let clinicIdRegex = /\/clinic\/([a-zA-Z0-9-]+)/;
      let newUrl = window.location.pathname.replace(clinicIdRegex, `/clinic/${clinic_id}`);
      window.history.replaceState({}, "", newUrl);

      // Reload the page
      window.location.reload();
    },
    boot_totango() {
      let self = this;

      let cus_id = "";

      self.user_clinics.forEach((clinic) => {
        if (clinic.id == self.current_clinic_id) {
          cus_id = clinic.stripe_customer_id;
        }
      });

      if (!cus_id) {
        return;
      }

      window.totango_options = {
        service_id: "SP-43701-00",
        module: "NVT",
        user: {
          name: self.current_user.name_given + " " + self.current_user.name_family,
          id: self.current_user.email,
          contact_account_role: self.highest_perm,
        },
        account: {
          id: cus_id,
        },
      };
      (function () {
        var tracker_name = window.totango_options.tracker_name || "totango";
        window.totango_tmp_stack = [];
        window[tracker_name] = {
          go: function () {
            return -1;
          },
          setAccountAttributes: function () {},
          identify: function () {},
          track: function (t, o, n, a) {
            window.totango_tmp_stack.push({
              activity: t,
              module: o,
              org: n,
              user: a,
            });
            return -1;
          },
        };
        var e = document.createElement("script");
        e.type = "text/javascript";
        e.async = true;
        e.src = ("https:" == document.location.protocol ? "https://" : "http://") + "tracker.totango.com/totango3.js";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(e, s);
      })();
    },

    boot_helpscout() {
      if (this.inIframe) {
        jQuery("#beacon-container").remove();
      } else {
        boot_helpscout(
          this.current_user,
          this.current_clinic_id,
          this.user_clinics,
          this.user_invites,
          this.app_config.helpscout_key,
          this.app_config.app_path,
          this.$i18n
        );
      }
    },
    dark_mode(value) {
      this.$vuetify.theme.dark = value;
      let prefs = cookie.getJSON("user_preferences");
      if (!prefs) {
        prefs = {};
      }
      prefs.dark_mode = value;
      cookie.set("user_preferences", prefs, 365);
    },
    success(message) {
      this.show_success = false;
      this.success_message = message;
      this.show_success = true;
    },
    api_error(api_response) {
      this.show_error = false;
      if (typeof api_response === "string") {
        // Strip any HTML
        let message = api_response;
        message = message.replace(/<\s*br\/*>/gi, "\n");
        message = message.replace(/<\s*a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 (Link->$1) ");
        message = message.replace(/<\s*\/*.+?>/gi, "\n");
        message = message.replace(/ {2,}/gi, " ");
        message = message.replace(/\n+\s*/gi, "\n\n");
        this.error_message = message;
      } else if (typeof api_response === "object") {
        if (api_response.error_message) {
          this.error_message = "Error: " + api_response.error_message;
        } else if (api_response.error) {
          if (typeof api_response.error === "string") {
            this.error_message = "Error: " + api_response.error;
          } else if (typeof api_response.error === "object") {
            if (api_response.error.reason) {
              this.error_message = "Error: " + api_response.error.reason;
            }
            if (api_response.error.description) {
              this.error_message += " (" + api_response.error.description + ")";
            }
          } else {
            this.error_message = "Error: Unknown Error";
          }
        }
      } else {
        this.error_message = "Unknown Error";
      }
      Vue.nextTick(() => {
        this.show_error = true;
      });
    },
    display_error(error_message) {
      this.show_error = false;
      this.error_message = error_message;
      this.show_error = true;
    },
    application_error(error) {
      if (error.response && error.response.status >= 500 && error.response.status != 503) {
        this.app_loading = false;

        Sentry.withScope((scope) => {
          scope.setExtra("response", JSON.parse(JSON.stringify(error.response)));
          scope.setExtra("full_error", JSON.parse(JSON.stringify(error)));
          if (error.response && error.response.headers && error.response.headers["x-cloud-trace-context"]) {
            scope.setExtra("gcloud_trace_id", error.response.headers["x-cloud-trace-context"]);
          }
          const event_id = Sentry.captureException(error);
          let event = { eventId: event_id };

          if (this.current_user) {
            event.user = {
              email: this.current_user.email,
              name: this.current_user.name_given + " " + this.current_user.name_family,
            };
          }

          if (this.$i18n.locale && this.$i18n.locale != "en") {
            event.lang = this.$i18n.locale;
          }

          event.onLoad = () => {
            setTimeout(() => {
              let close_elem = document.querySelector(".sentry-error-embed .close");

              if (close_elem) {
                close_elem.addEventListener("click", () => {
                  window.location.reload();
                });
              }

              let powered_elem = document.querySelector(".sentry-error-embed .powered-by");
              if (powered_elem) {
                powered_elem.style.display = "none";
              }
            }, 50);
          };
          Sentry.showReportDialog(event);
        });
      } else {
        if (error.response) {
          Sentry.captureException(error);
          this.api_error(error.response.data);
        } else {
          Sentry.captureException(error);
        }
      }
    },
    user_updated: function (updated_user) {
      let self = this;
      if (updated_user.id == this.current_user.id) {
        axios.get("/api/user/current").then(function (response) {
          if (response.data.status == "success") {
            self.$store.commit("setCurrentUser", response.data.data.user);
          }
        });
      }
    },
    unmasquerade: function () {
      axios.post("/api/user/unmasquerade").then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          if (window.Beacon) {
            window.Beacon("logout");
          }
          window.location.reload();
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    open_helpscout() {
      open_helpscout(this.current_user, this.current_clinic_id, this.user_clinics, this.user_invites);
    },
  },
};
</script>

<style>
.adminimal .content-header #app {
  height: 64px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}

#toolbar-administration {
  display: none;
}

.theme--dark .page_toolbar {
  background: black;
}

.woot-widget-bubble {
  bottom: 30px !important;
}

#rotate_banner {
  display: none;
}

/* iPad in portriat */
@media only screen and (orientation: portrait) {
  #rotate_banner {
    display: block !important;
  }
}

/* Blur non-overlay on overlay pop */
.v-overlay--active + .v-application--wrap {
  filter: blur(1px);
}
.v-overlay--active + .v-application--wrap .clinic-page-user-line {
  filter: blur(6px);
}
.v-overlay--active + .v-application--wrap .clinic-page-invite-line {
  filter: blur(6px);
}

/* Hide HelpScout when using an overlay that is fullscreen */
.v-dialog__content--active + #beacon-container {
  display: none;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>
